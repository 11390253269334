{
  "name": "taas-portal-frontend",
  "version": "0.0.15",
  "scripts": {
    "ng": "ng",
    "lint": "ng lint",
    "test": "ng test",
    "start": "ng serve",
    "start-prod": "ng serve --configuration production",
    "start-preprod": "ng serve --configuration preprod",
    "start-de": "ng serve --configuration=de",
    "start-en": "ng serve --configuration=en",
    "start-se": "ng serve --configuration=se",
    "translate": "ng extract-i18n",
    "build": "ng build",
    "build-prod": "ng build --configuration production",
    "build-preprod": "ng build --configuration preprod",
    "build-dev": "ng build --configuration development",
    "watch": "ng build --watch --configuration development"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.5",
    "@angular/cdk": "^17.3.5",
    "@angular/common": "^17.3.5",
    "@angular/compiler": "^17.3.5",
    "@angular/core": "^17.3.5",
    "@angular/forms": "^17.3.5",
    "@angular/material": "^17.3.5",
    "@angular/platform-browser": "^17.3.5",
    "@angular/platform-browser-dynamic": "^17.3.5",
    "@angular/router": "^17.3.5",
    "@material-icons/svg": "1.0.33",
    "jwt-decode": "^4.0.0",
    "keycloak-angular": "^15.2.1",
    "keycloak-js": "^24.0.3",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "utimaco-common-ui-angular": "https://repo.fra1.us.utimaco.cloud/repository/npm-hosted/utimaco-common-ui-angular/-/utimaco-common-ui-angular-0.13.1.tgz",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.5",
    "@angular-eslint/builder": "^17.3.5",
    "@angular-eslint/eslint-plugin": "^17.3.5",
    "@angular-eslint/eslint-plugin-template": "^17.3.5",
    "@angular-eslint/schematics": "^17.3.4",
    "@angular-eslint/template-parser": "^17.3.5",
    "@angular/cli": "^17.3.5",
    "@angular/compiler-cli": "^17.3.5",
    "@angular/localize": "^17.3.5",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "7.10.0",
    "@typescript-eslint/parser": "7.10.0",
    "eslint": "^8.57.0",
    "jasmine-core": "~5.1.2",
    "karma": "~6.4.3",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "ng-extract-i18n-merge": "^2.12.0",
    "typescript": "~5.4.5"
  }
}
